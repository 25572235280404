export default {
  center: {
    gmdj: "購買道具",
    ddje: "訂單金額",
    ye: "餘額",
    rghk: "匯款轉帳",
    wxts: "溫馨提示：適度娛樂，理性消費",
    zxkf: "在綫客服",
    lxrg: "聯繫人工客服",
    qnwb: "請您務必聯繫人工客服QQ進行手動處理",
    zh: "帳戶",
    zhCon: "嚴煉",
    copy: "複製",
    kh: "卡號",
    khh: "開戶行：中國銀行（京東大道支行）",
    khd: "開戶地",
    qkk: "去匯款",
    qr: "確認",
    rghkdb: "人工匯款單筆交易金額",
    xdy: "需大於100人民幣",
    qxzf: "請選擇支付方式",
    bao: "寶",
    zrzq: "載入中，請您耐心等待",
    zfqq: "支付請求出現異常",
    gllq: "該瀏覽器不支持複製",
    gczf: "通過mycard支付需繳納30%手續費，建議使用其他儲值方式",
    ptby: "平台幣餘額不足,請點擊懸浮按鈕>>彈窗 儲值平台幣",
    waby: "哇塞幣余額不足，前往哇塞手遊APP充值",
    cz: "儲值",
    ptb: "平台幣",
    sfje: "實付金額",
    jedy: "（金額低於一美元，無法支付）",
    czjedy30: "储值金额必須大於30",
    dkwssy: "打開哇塞手遊",
    smrz: "根據相關規定，進行網絡遊戲、網絡交易等行爲時需要進行實名認證",
    smrzbt: "實名認證",
    xz: "選擇",
    qxz: "請選擇",
    jxyx: "繼續遊戲",
    wsbzf: "哇塞幣支付",
    hkxx: "匯款信息",
    je: "金額",
    wxsm: "微信掃碼支付",
    dbcz: "（單筆充值 >3000元推薦使用）",
    vb: "V幣",
    yj: "原價",
    man: "滿",
    jian: "減",
    wu: "無",
    xs: "限時",
    xtb: "新臺幣",
    smewm: "掃描二維碼支付",
    jietu: "截圖後，在微信“掃一掃”識別二維碼支付",
    bsywab: "不使用哇塞幣",
    sywab: "使用哇塞幣",
    wsbye: "使用哇塞幣抵扣",
    wsbzk: "哇塞幣抵扣",
    dqjybky: "當前交易不可用",
    dzjzbky: "凍結中，暫不可用",
    qrzf: "確認支付",
    jzz: "加載中...",
    yhxxds: "用戶信息丟失",
    zfts: "官方溫馨提醒：防範詐騙風險，謹防虛擬情感，理性消費。請您註意資金安全！",
    lqz: "領取中...",
    ptbzf: "平台幣支付",
    zfbzf: "支付寶支付",
    hqddztz: "獲取訂單狀態中",
    zfb: "支付寶",
    zfz: "支付中",
    yhhd: "優惠活動",
    yxzkyh: "遊戲折扣優惠",
    zwyxzk: "暫無遊戲折扣",
    ykyyxzk: "有可用遊戲折扣",
    djqyh: "代金券優惠",
    byzkdj: "不與折扣疊加",
    zwdjq: "暫無代金券",
    zkqb: "展開全部",
    lbnr: "禮包內容",
    qd: "確 定",
    xzyxzk: "選擇遊戲折扣",
    yxzk: "遊戲折扣",
    bzbkydjqyqsy: "備注：不可與代金券優惠壹起使用",
    sm: "說明",
    rule0: "計算方式：訂單金額*遊戲折扣",
    rule1_1: "1、滿減券計算方式：訂單金額*遊戲折扣-滿減券",
    rule1_2: "2、折扣券計算方式：訂單金額*遊戲折扣*折扣券",
    rule2_1: "1、滿減券計算方式：（訂單金額-滿減券）*遊戲折扣",
    rule2_2: "2、折扣券計算方式：訂單金額*折扣券*遊戲折扣",
    rule_1_1: "1、滿減券計算方式：訂單金額-滿減券",
    rule_1_2: "2、折扣券計算方式：訂單金額*折扣券",
    rule_3_1: "計算方式：訂單金額",
    mjq: "滿減券",
    xzzkwfsydjq: "您選擇的遊戲折扣無法使用代金券優惠，是否確認使用",
  },
  sdkvoucher: {
    ydj: "已凍結",
    ygq: "已過期",
    syfw: "適用範圍",
    sxsj: "生效時間",
    lqsj: "領取時間",
    gz: "規則",
    lqzx: "領取中心",
    djqgzy: "代金券只能在可領取時間領取，領取時間 過了無法領取。",
    djqgze: "代金券須在有效期內使用，逾期自動失效。",
    djqgzs: "代金券須在有效期內使用，逾期自動失效。",
    djqgzf: "滿足優惠規則才可以使用。",
    djqgzw: "在法律允許範圍內，保留對代金券的解釋權。",
    gb: "關閉",
  },
  transfer: {
    dkyh: "打開微信 > 收付款 >轉帳到銀行卡",
    fzyx: "複製以下內容，填充到轉帳信息中",
    qrxx: "確認信息無誤後，進行轉賬",
    fwff: "服務費費力0.1%由微信收取",
    zzcg: "轉帳成功後，請將儲值完成截屏保存後發送到官方QQ2134093280聯繫客服人員進行儲值",
    lxkf: "聯繫客服人員後退出此頁面等待到帳即可",
    dwck: "點我查看如何轉帳",
    hkxx: "點擊進入微信",
    rwfz: "如無法自動打開，請手動打開微信",
    wxzz: "微信轉賬到銀行卡",
  },
  paPal: {
    vvzh: "VV賬號",
    ptbs: "平台幣數量",
    qsrp: "請輸入平台幣數量",
    ptbc: "平台幣儲值完成後，在遊戲內儲值元寶，選擇“平台幣支付”進行購買",
    tysy: "統一使用美元支付。1美元=6平台幣",
    bxsr: "必須輸入不小於10的整數",
    wxtx: "溫馨提醒",
  },
  wallet: {
    dqye: "當前餘額",
    czjl: "儲值記錄",
    kscz: "快速儲值",
    sj: "售價",
    tjdd: "提交訂單",
    jyqb: "已經全部加載完畢",
    wxtx: "溫馨提醒",
    qd: "確定",
  },
  result: {
    text: "您可返回遊戲查看支付結果",
    fhyx: "返回遊戲",
    zfsb: "支付失敗",
  },
  gift: {
    lb: "禮包",
    dlq: "待領取",
    lq: "領取",
    wdb: "未達標",
    mrkl: "明日可領",
    ylq: "已領取",
    yxndh: "領取後在遊戲內兌換",
    sysm: "使用說明",
    fzlbm: "復制禮包兌換碼",
    jryx: "進入遊戲，打開遊戲內對應禮包領取頁面",
    lbmlq: "粘貼禮包碼並領取",
    dhrq: "禮包兌換截至日期",
    lqtj: "領取條件",
    ghtq: "公會特權發放",
    lbm: "禮包碼",
    fz: "復制",
    djzk: "點擊展開更多",
    djsq: "點擊收起",
    gb: "關閉",
    tip: "禮包已自動發送至遊戲內，如未收到，請拿禮包碼進入遊戲內兌換",
    fzcg: "復制成功",
    bzcfz: "該瀏覽器不支持自動復制",
    wdlb: "我的禮包",
    dw: "檔位",
    nullGiftTips1: "客官，暫無禮包喔~",
    nullGiftTips2: "客官，暫無待領取禮包~",
    nullGiftTips3: "客官，暫無已領取禮包~",
    syqk: "剩余情況",
  },
  coupon: {
    lq: "領取",
    wxz: "無限制",
    ylw: "已領完",
    ylq: "已領取",
    dbfyx: "適用於大部分遊戲",
    ysy: "已使用（僅展示近一周信息）",
    ygq: "已過期（僅展示近一個月信息）",
    bsyyhq: "不使用優惠券",
  },
  common: {
    hq: "獲取",
    sb: "失敗",
    sm: "掃碼",
    wlcs: "網絡請求超時，請更換網絡重試",
    hqwxsb: "獲取微信支付配置失敗",
    tjcfdd: "請勿重復提交訂單！",
  },
  registraDialog: {
    title: "用戶注冊服務協議和遊戲隱私保護指引",
    yhzc: "用戶注冊服務協議",
    yxys: "遊戲隱私保護指引",
    tip: "感謝您使用本遊戲。我們通過《用戶注冊服務協議》和《遊戲隱私保護指引》幫助您了解我們手機、使用、存儲和共享個人信息的情況，以及您所享有的相關權利。",
    new: "《遊戲隱私保護指引》和《用戶注冊服務協議》有更新，請您閱讀並同意協議內容後繼續體驗遊戲哦。如您拒絕，將無法進入遊戲。",
    hasnew:
      "有更新，請您閱讀並同意協議內容後繼續體驗遊戲哦。如您拒絕，將無法進入遊戲",
    ljxx: "了解詳細信息。如您同意，請點擊“同意”開始接受我們的服務。",
    djck: "點擊查看",
    jj: "拒絕",
    ydbty: "我已閱讀並同意",
    ktg: "您可通過閱讀",
    newtip:
      "在您使用本遊戲前，請您務必閱讀並同意用戶注冊協議和遊戲隱私保護政策，感謝您的信任！若您仍不同意以上信息，很遺憾我們將無法爲您提供服務，感謝您的理解。",
    wxts: "在您使用本遊戲前，請您務必閱讀並同意用戶注冊協議，感謝您的信任！若您仍不同意以上信息，很遺憾我們將無法爲您提供服務，感謝您的理解。",
    nwxts:
      "在您使用本遊戲前，請您務必閱讀並同意用戶注冊協議，感謝您的信任！若您仍不同意以上信息，很遺憾我們將無法爲您提供服務，感謝您的理解。",
  },
  payTipsDialog: {
    tip: "當前付款金額有優惠未使用，是否按",
  },
  realName: {
    title: "實名認證",
    gjfl: "根據相關法律規定，進行網絡遊戲、網絡交易等行爲時需要進行實名認證",
    qsrxm: "請輸入真實姓名",
    qsrid: "請輸入身份證號碼",
    qsrsj: "請輸入手機號碼",
    zy1: "1.姓名、身分證需屬於同一人",
    zy2: "2.實名認證通過之後，不可更改",
    qsrzqid: "請輸入正確身分證號碼",
    qsyzqsj: "請輸入正確手機號碼",
  },
  qrCode: {
    smzf: "掃碼支付",
    xdsb: "二維碼產生失敗,請重新下單",
    fhyxck: "您可返回遊戲查看",
  },
};
