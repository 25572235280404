import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Bridge from "./utils/bridge.js";
import "normalize.css";
import "./styles/styles.scss";
import "./styles/icon.scss";
import "./styles/index.scss";
//繁体化
import VueI18n from "vue-i18n";
import cnlang from "./lang/cn.js";
import twlang from "./lang/tw.js";
//md5加密
import md5 from "js-md5";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import "vue2-toast/lib/toast.css";
import Toast from "vue2-toast";
import VConsole from "vconsole";
Vue.use(Toast, {
  defaultType: "bottom",
  duration: 2000,
  wordWrap: false,
  width: "auto",
});
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "cn", // 默认语言
  messages: {
    cn: cnlang,
    tw: twlang,
  },
});
Vue.config.productionTip = false;
Vue.prototype.$md5 = md5;
Vue.prototype.$bridge = Bridge;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#root");

document.body.addEventListener("touchstart", function () {});

// const body = document.documentElement
// body.setAttribute('data-theme', 'default')
console.log("VUE_APP_VCONSOLE", process.env);
if (process.env.VUE_APP_VCONSOLE === "true") {
  const vConsole = new VConsole();
}
