import store from "@/store";
const API_INIT_URL = process.env.VUE_APP_INIT_URL

/**
 * 获取接口
 * @param {*} platFormId
 * @returns
 */
export const getApiData = async(platFormId) => {
  return await new Promise((resolve) => {
     fetch(API_INIT_URL).then(res => res.json()).then((res) => {
      setApi(platFormId, res)
      resolve(true)
    })
  })
}

/**
 * 设置接口
 * @param {*} platFormId
 * @param {*} api
 */
const setApi = (platFormId, api) => {
  const { apiLiteUrl, apiLogUrl, apiUrl, channelPromotionUrl, imUrl, sdkLogUrl } = api
  const apiObj = {
    imUrl,
    channelPromotionUrl,
    apiLogUrl,
    apiLiteUrl: apiLiteUrl[platFormId] || apiLiteUrl.default ,
    apiUrl: apiUrl[platFormId] || apiUrl.default ,
    sdkLogUrl
  }
  localStorage.setItem('api', JSON.stringify(apiObj))
  store.dispatch("getApiUrl", {
    apiUrl: apiObj.apiLiteUrl,
    apilogUrl: apiObj.apiLogUrl,
    h5csUrl: process.env.VUE_APP_SERVICE_API
  });
}
