import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl:{}
  },
  getters:{
    apiUrl: state => state.apiUrl
  },
  mutations: {
    GETAPIURL: (state, apiUrl) => {
      state.apiUrl = apiUrl;
    },
  },
  actions: {
    // 获取api配置
    getApiUrl({ commit }, apiUrl) {
      commit("GETAPIURL", apiUrl);
    },
  }
})
