import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import qs from "qs";
import axios from "axios";
import { getApiData } from "@/utils/api"
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/megane",
      redirect: "/pay",
      meta: {
        title: "充值中心",
      },
    },
    {
      path: "/pay/result",
      component: () => import("./views/pay/result.vue"),
      meta: {
        title: "订单结果",
      },
    },
    {
      path: "/pay/transferConfirm",
      component: () => import("./views/pay/transferConfirm.vue"),
      meta: {
        title: "转账确认",
      },
    },
    {
      path: "/pay/blankPage",
      component: () => import("./views/pay/blankPage.vue"),
      meta: {
        title: "blankPage",
      },
    },
    {
      path: "/service",
      component: () => import("./views/service/kuaifu.vue"),
      meta: {
        title: "客服中心",
      },
    },
    {
      path: "/pay/payPal",
      component: () => import("./views/pay/payPal.vue"),
      meta: {
        title: "payPal充值",
      },
    },
    {
      path: "/wallet/index",
      component: () => import("./views/wallet/index.vue"),
      meta: {
        title: "平台币充值",
      },
    },
    {
      //盒子平台币充值
      path: "/wallet/boxWallet",
      component: () => import("./views/wallet/boxWallet.vue"),
      meta: {
        title: "平台币充值",
      },
    },
    {
      path: "/wallet/record",
      component: () => import("./views/wallet/record.vue"),
      meta: {
        title: "平台币充值记录",
      },
    },
    {
      path: "/pay/failPage",
      component: () => import("./views/pay/failPage.vue"),
      meta: {
        title: "订单结果",
      },
    },
    {
      path: "/gameExt/pay",
      component: () => import("./views/gameExt/pay.vue"),
      meta: {
        title: "至尊支付",
      },
    },
    {
      path: "/boxService",
      component: () => import("./views/service/boxKuaifu.vue"),
      meta: {
        title: "返利盒子客服中心",
      },
    },
    {
      path: "/zhzuGift/index",
      component: () => import("./views/zhzuGift/index.vue"),
      meta: {
        title: "至尊下载礼包",
      },
    },
    {
      path: "/RegistraDialog",
      component: () => import("./components/RegistraDialog"),
      meta: {
        title: "用户注册服务协议和游戏隐私保护指引",
      },
    },
    {
      path: "/RegistraDialog2",
      component: () => import("./components/RegistraDialog2"),
      meta: {
        title: "用户注册服务协议和游戏隐私保护指引",
      },
    },
    {
      path: "/gift/index",
      component: () => import("./views/gift/index.vue"),
      meta: {
        title: "礼包",
      },
    },
    {
      path: "/gift/mygift",
      component: () => import("./views/gift/mygift.vue"),
      meta: {
        title: "我的礼包",
      },
    },
    {
      path: "/gift/details",
      component: () => import("./views/gift/details.vue"),
      meta: {
        title: "礼包详情",
      },
    },
    {
      path: "/coupon/index",
      component: () => import("./views/coupon/index.vue"),
      meta: {
        title: "代金券",
      },
    },
    {
      path: "/5uwan/newComersWelfare",
      component: () => import("./views/5uwan/newComersWelfare/index.vue"),
      meta: {
        title: "新人福利代金券",
      },
    },
    {
      path: "/5uwan/logoutId/index",
      component: () => import("./views/5uwan/logoutId/index.vue"),
      meta: {
        title: "账号注销",
      },
    },
    {
      path: "/5uwan/coupon",
      component: () => import("./views/5uwan/coupon/index.vue"),
      meta: {
        title: "代金券",
      },
    },
    ,
    {
      path: "/5uwan/worryFreeMall",
      component: () => import("./views/5uwan/worryFreeMall/index.vue"),
      meta: {
        title: "哇塞商城",
      },
    },
    {
      path: "/5uwan/worryFreeMall/details",
      component: () => import("./views/5uwan/worryFreeMall/details.vue"),
      meta: {
        title: "商品详细",
      },
    },
    {
      path: "/5uwan/trumpetRecycling/index",
      component: () => import("./views/5uwan/trumpetRecycling/index.vue"),
      meta: {
        title: "小号回收",
      },
    },
    {
      path: "/5uwan/luckDraw/index",
      component: () => import("./views/5uwan/luckDraw/index.vue"),
      meta: {
        title: "宝箱抽奖",
      },
    },
    {
      path: "/5uwan/MoneyScard/index",
      component: () => import("./views/5uwan/MoneyScard/index.vue"),
      meta: {
        title: "省钱卡",
      },
    },
    {
      path: "/5uwan/openCard/index",
      component: () => import("./views/5uwan/openCard/index.vue"),
      meta: {
        title: "开通省钱卡",
      },
    },
    {
      path: "/5uwan/integral/index",
      component: () => import("./views/5uwan/integral/index.vue"),
      meta: {
        title: "哇塞夺宝",
      },
    },
    {
      path: "/5uwan/commodityDetails/index",
      component: () => import("./views/5uwan/commodityDetails/index.vue"),
      meta: {
        title: "商品详情",
      },
    },
    {
      path: "/5uwan/buySucceed/index",
      component: () => import("./views/5uwan/buySucceed/index.vue"),
      meta: {
        title: "哇塞夺宝",
      },
    },
    {
      path: "/5uwan/snatchRule/index",
      component: () => import("./views/5uwan/snatchRule/index.vue"),
      meta: {
        title: "夺宝规则",
      },
    },
    {
      path: "/5uwan/logoutId/index",
      component: () => import("./views/5uwan/logoutId/index.vue"),
      meta: {
        title: "账号注销",
      },
    },
    {
      path: "/5uwan/redEnvelopeFission/index",
      component: () => import("./views/5uwan/redEnvelopeFission/index.vue"),
      meta: {
        title: "赚平台币",
      },
    },
    {
      path: "/5uwan/welfareCode/index",
      component: () => import("./views/5uwan/welfareCode/index.vue"),
      meta: {
        title: "福利码兑换",
      },
    },
    {
      path: "/5uwan/worryFreeCoinRecharge/index",
      component: () => import("./views/5uwan/worryFreeCoinRecharge/index.vue"),
      meta: {
        title: "兑换卡",
      },
    },
    {
      path: "/5uwan/worryFreeRules/index",
      component: () => import("./views/5uwan/worryFreeRules/index.vue"),
      meta: {
        title: "哇塞豆规则说明",
      },
    },
    {
      path: "/5uwan/gameTopup/index",
      component: () => import("./views/5uwan/gameTopup/index.vue"),
      meta: {
        title: "游戏充值",
      },
    },
    {
      path: "/5uwan/gameOpen/index",
      component: () => import("./views/5uwan/gameOpen/index.vue"),
      meta: {
        title: "开服信息",
      },
    },
    {
      path: "/5uwan/gameTurn/index",
      component: () => import("./views/5uwan/gameTurn/index.vue"),
      meta: {
        title: "转游中心",
      },
    },
    {
      path: "/5uwan/gameWelfare/index",
      component: () => import("./views/5uwan/gameWelfare/index.vue"),
      meta: {
        title: "转游福利",
      },
    },
    {
      path: "/nomalDownload/index",
      component: () => import("./views/nomaldownload/index.vue"),
      meta: {
        title: "推广页",
      },
    },
    {
      path: "/warStatic",
      name: "warStatic",
      component: () => import("./views/static/warStatic/index.vue"),
      meta: {
        title: "哇塞大军团开启内测",
      },
    },
    {
      path: "/outerVoucher",
      component: () => import("./views/outerVoucher/Voucher.vue"),
      meta: {
        title: "领取代金券",
      },
    },
    //w3SDK礼包-新
    {
      path: "/w3sdkGift/index",
      component: () => import("./views/w3sdkGift/index.vue"),
      meta: {
        title: "礼包",
      },
    },
    {
      path: "/w3sdkGift/details",
      component: () => import("./views/w3sdkGift/details.vue"),
      meta: {
        title: "礼包详情",
      },
    },
    {
      path: "/downloadApp/index",
      component: () => import("./views/w3sdkGift/downloadApp/index.vue"),
      meta: {
        title: "",
      },
    },
    {
      path: "/successReceive/index",
      component: () => import("./views/w3sdkGift/successReceive/index.vue"),
      meta: {
        title: "",
      },
    },
    {
      path: "/inviteFriends",
      name: "inviteFriends",
      component: () =>
        import("@/views/wa3RedEnvelopes/inviteFriendsLayout/index.vue"),
      meta: {
        title: "邀请好友",
      },
    },
    {
      path: "/wa3sdkRedPacket",
      name: "wa3sdkRedPacket",
      component: () =>
        import("@/views/wa3RedEnvelopes/wa3sdkRedPacket/index.vue"),
      meta: {
        title: "红包活动",
      },
    },
    // {
    //     path: '/minssion',
    //     name: 'minssion',
    //     component: () =>
    //         import('@/views/wa3RedEnvelopes/minssion/index.vue'),
    //     meta: {
    //         title: '邀请好友'
    //     }
    // },
    {
      path: "/getredEnvelope",
      name: "getredEnvelope",
      component: () =>
        import("@/views/wa3RedEnvelopes/getredEnvelope/index.vue"),
      meta: {
        title: "红包活动",
      },
    },
    {
      path: "/redEnvelopeRegister",
      name: "redEnvelopeRegister",
      component: () =>
        import("@/views/wa3RedEnvelopes/redEnvelopeRegister/index.vue"),
      meta: {
        title: "红包活动",
      },
    },
    {
      path: "/downLoadNow",
      name: "downLoadNow",
      component: () => import("@/views/wa3RedEnvelopes/downLoadNow/index.vue"),
      meta: {
        title: "红包活动",
      },
    },
    {
      path: "/tradedescription",
      name: "tradeDescription",
      component: () => import("@/views/tradeDescription/index.vue"),
      meta: {
        title: "交易说明",
      },
    },
    // {
    //   // path: "/newcenter", //暂时测试用
    //   path: "/pay2",
    //   name: "newCenter",
    //   component: () => import("@/views/pay/new-center/index_old.vue"),
    //   meta: {
    //     title: "充值中心",
    //   },
    // },
    {
      // path: "/newcenter", //暂时测试用
      path: "/pay",
      name: "newCenter",
      component: () => import("@/views/pay/new-center/index.vue"),
      meta: {
        title: "充值中心",
      },
    },
    {
      //聚合支付页
      path: "/aggregationPay",
      name: "aggregationPay",
      component: () => import("@/views/pay/aggregationPay/index.vue"),
      meta: {
        title: "充值中心",
      },
    },
    {
      path: "/vvsdkVoucher",
      name: "sdkVoucher",
      component: () => import("@/views/vvsdkvoucher/index.vue"),
      meta: {
        title: "代金券",
      },
    },
    {
      path: "/qrcodePay",
      name: "qrcodePay",
      component: () => import("@/views/pay/new-center/qrcodePay.vue"),
      meta: {
        title: "支付",
      },
    },
    {
      path: "/pcPaySuccess",
      name: "pcPaySuccess",
      component: () => import("@/views/pay/new-center/pcPaySuccess.vue"),
      meta: {
        title: "支付成功",
      },
    },
  ],
});

let url;
router.beforeEach(async(to, from, next) => {
  if (process.env.NODE_ENV == "development") {
    url = "";
  } else {
    url = "//" + location.hostname;
  }
  await getApiData(to.query.platformId)
  next();
  to.meta.title && (document.title = to.meta.title);
});

export default router;
