export default {
  center: {
    gmdj: "购买道具",
    ddje: "订单金额",
    ye: "余额",
    rghk: "人工汇款",
    wxts: "温馨提示：适度娱乐，理性消费",
    zxkf: "在线客服",
    lxrg: "联系人工客服",
    qnwb: "请您务必联系官方客服QQ进行手动处理",
    zh: "账户",
    zhCon: "严炼",
    copy: "复制",
    kh: "卡号",
    khh: "开户行：中国银行（京东大道支行）",
    khd: "开户地",
    qkk: "去汇款",
    qr: "确认",
    rghkdb: "人工汇款单笔交易金额",
    xdy: "需大于100元人民币",
    qxzf: "请选择支付方式",
    bao: "宝",
    zrzq: "载入中，请您耐心等待",
    zfqq: "支付请求出现异常",
    gllq: "该浏览器不支持自动复制",
    gczf: "通过mycard支付需缴纳30%手续费，建议使用其他支付方式支付",
    ptby: "平台币余额不足,请点击悬浮按钮>>弹窗 充值平台币",
    waby: "哇塞币余额不足，前往哇塞手游APP充值 ",
    cz: "充值",
    ptb: "平台币",
    sfje: "实付金额",
    jedy: "（金额低于一美元，无法支付）",
    czjedy30: "充值金额必须大于30",
    dkwssy: "打开哇塞手游",
    smrz: "根据相关规定，进行网络游戏、网络交易等行为时需要进行实名认证",
    smrzbt: "实名认证",
    xz: "选择",
    qxz: "请选择",
    jxyx: "继续游戏",
    wsbzf: "哇塞币支付",
    hkxx: "汇款信息",
    je: "金额",
    wxsm: "微信扫码支付",
    dbcz: "（单笔充值 >3000元推荐使用）",
    vb: "V币",
    yj: "原价",
    man: "满",
    jian: "减",
    wu: "无",
    xs: "限时",
    xtb: "新台币",
    smewm: "扫描二维码支付",
    jietu: "截图后，在微信“扫一扫”识别二维码支付",
    bsywab: "不使用哇塞币",
    sywab: "使用哇塞币",
    wsbye: "使用哇塞币抵扣",
    wsbzk: "哇塞币抵扣",
    dqjybky: "当前交易不可用",
    dzjzbky: "冻结中，暂不可用",
    qrzf: "确认支付",
    jzz: "加载中...",
    yhxxds: "用户信息丢失",
    zfts: "官方温馨提醒：防范诈骗风险，谨防虚拟情感，理性消费。请您注意资金安全！",
    lqz: "领取中...",
    ptbzf: "平台币支付",
    zfbzf: "支付宝支付",
    hqddztz: "获取订单状态中",
    zfb: "支付宝",
    zfz: "支付中",
    yhhd: "优惠活动",
    yxzkyh: "游戏折扣优惠",
    zwyxzk: "暂无游戏折扣",
    ykyyxzk: "有可用游戏折扣",
    djqyh: "代金券优惠",
    byzkdj: "不与折扣叠加",
    zwdjq: "暂无代金券",
    zkqb: "展开全部",
    lbnr: "礼包内容",
    qd: "确 定",
    xzyxzk: "选择游戏折扣",
    yxzk: "游戏折扣",
    bzbkydjqyqsy: "备注：不可与代金券优惠一起使用",
    sm: "说明",
    rule0: "计算方式：订单金额*游戏折扣",
    rule1_1: "1、满减券计算方式：订单金额*游戏折扣-满减券",
    rule1_2: "2、折扣券计算方式：订单金额*游戏折扣*折扣券",
    rule2_1: "1、满减券计算方式：（订单金额-满减券）*游戏折扣",
    rule2_2: "2、折扣券计算方式：订单金额*折扣券*游戏折扣",
    rule_1_1: "1、满减券计算方式：订单金额-满减券",
    rule_1_2: "2、折扣券计算方式：订单金额*折扣券",
    rule_3_1: "计算方式：订单金额",
    mjq: "满减券",
    xzzkwfsydjq: "您选择的游戏折扣无法使用代金券优惠，是否确认使用",
  },
  sdkvoucher: {
    ydj: "已冻结",
    ygq: "已过期",
    syfw: "适用范围",
    sxsj: "生效时间",
    lqsj: "领取时间",
    gz: "规则",
    lqzx: "领取中心",
    djqgzy: "代金券只能在可领取时间领取，领取时间 过了无法领取。",
    djqgze: "代金券须在有效期内使用，逾期自动失效。",
    djqgzs: "代金券只能在适用范围使用。",
    djqgzf: "满足优惠规则才可以使用。",
    djqgzw: "在法律允许范围内，保留对代金券的解释权。",
    gb: "关闭",
  },
  transfer: {
    dkyh: "打开微信—收付款—转账到银行卡",
    fzyx: "复制以下内容，填充到转账信息中",
    qrxx: "确认信息无误后，进行转账",
    fwff: "服务费费率0.1％由微信收取",
    zzcg: "转账成功后，请将支付完成截图保存后发送到官方QQ2134093280联系客服人员进行充值",
    lxkf: "联系客服人员充值后退出此页面等待到账即可",
    dwck: "点我查看如何转账",
    djjr: "点击进入微信",
    rwfz: "如无法自动打开，请手动打开微信",
    wxzz: "微信转账到银行卡指引",
  },
  paPal: {
    vvzh: "VV账号",
    ptbs: "平台币数量",
    qsrp: "请输入平台币数量",
    ptbc: "平台币充值完成后，在游戏内充值元宝，选择“平台币支付”进行购买",
    tysy: "统一使用美元支付。1美元=6平台币",
    bxsr: "必须输入不小于10的正整数",
    wxtx: "温馨提醒",
  },
  wallet: {
    dqye: "当前余额",
    czjl: "充值记录",
    kscz: "快速充值",
    sj: "售价",
    tjdd: "提交订单",
    jyqb: "已经全部加载完毕",
    wxtx: "温馨提醒",
    qd: "确定",
  },
  result: {
    text: "您可返回游戏查看支付结果",
    fhyx: "返回游戏",
    zfsb: "支付失败",
  },
  gift: {
    lb: "礼包",
    dlq: "待领取",
    lq: "领取",
    wdb: "未达标",
    mrkl: "明日可领",
    ylq: "已领取",
    yxndh: "领取后在游戏内兑换",
    sysm: "使用说明",
    fzlbm: "复制礼包兑换码",
    jryx: "进入游戏，打开游戏内对应礼包领取页面",
    lbmlq: "粘贴礼包码并领取",
    dhrq: "礼包兑换截至日期",
    lqtj: "领取条件",
    ghtq: "公会特权发放",
    lbm: "礼包码",
    fz: "复制",
    djzk: "点击展开更多",
    djsq: "点击收起",
    gb: "关闭",
    tip: "礼包已自动发送至游戏内，如未收到，请拿礼包码进入游戏内兑换",
    fzcg: "复制成功",
    bzcfz: "该浏览器不支持自动复制",
    wdlb: "我的礼包",
    dw: "档位",
    nullGiftTips1: "客官，暂无礼包喔~",
    nullGiftTips2: "客官，暂无待领取礼包~",
    nullGiftTips3: "客官，暂无已领取礼包~",
    syqk: "剩余情况",
  },
  coupon: {
    lq: "领取",
    wxz: "无限制",
    ylw: "已领完",
    ylq: "已领取",
    dbfyx: "适用于大部分游戏",
    ysy: "已使用（仅展示近一周信息）",
    ygq: "已过期（仅展示近一个月信息）",
    bsyyhq: "不使用优惠券",
  },
  common: {
    hq: "获取",
    sb: "失败",
    sm: "扫码",
    wlcs: "网络请求超时，请更换网络重试",
    hqwxsb: "获取微信支付配置失败",
    tjcfdd: "请勿重复提交订单！",
  },
  registraDialog: {
    title: "用户注册服务协议和游戏隐私保护指引",
    yhzc: "用户注册服务协议",
    yxys: "游戏隐私保护指引",
    tip: "感谢您使用本游戏。我们通过《用户注册服务协议》和《游戏隐私保护指引》帮助您了解我们手机、使用、存储和共享个人信息的情况，以及您所享有的相关权利。",
    new: "《游戏隐私保护指引》和《用户注册服务协议》有更新，请您阅读并同意协议内容后继续体验游戏哦。如您拒绝，将无法进入游戏。",
    hasnew:
      "有更新，请您阅读并同意协议内容后继续体验游戏哦。如您拒绝，将无法进入游戏",
    ljxx: "了解详细信息。如您同意，请点击“同意”开始接受我们的服务。",
    djck: "点击查看",
    jj: "拒绝",
    ydbty: "我已阅读并同意",
    ktg: "您可通过阅读",
    newtip:
      "在您使用本游戏前，请您务必阅读并同意用户注册协议和游戏隐私保护政策，感谢您的信任！若您仍不同意以上信息，很遗憾我们将无法为您提供服务，感谢您的理解。",
    wxts: "在您使用本游戏前，请您务必阅读并同意用户注册协议，感谢您的信任！若您仍不同意以上信息，很遗憾我们将无法为您提供服务，感谢您的理解。",
    nwxts:
      "在您使用本游戏前，请您务必阅读并同意用户注册协议，感谢您的信任！若您仍不同意以上信息，很遗憾我们将无法为您提供服务，感谢您的理解。",
  },
  payTipsDialog: {
    tip: "当前付款金额有优惠未使用，是否按",
  },
  realName: {
    title: "实名认证",
    gjfl: "根据相关法律规定，进行网络游戏、网络交易等行为时需要进行实名认证",
    qsrxm: "请输入真实姓名",
    qsrid: "请输入身份证号码",
    qsrsj: "请输入手机号码",
    zy1: "1.姓名、身份证需属于同一人",
    zy2: "2.实名认证通过之后，不可更改",
    qsrzqid: "请输入正确身份证号码",
    qsyzqsj: "请输入正确手机号码",
    smcg: "实名认证成功",
    smyc: "实名认证异常",
  },
  qrCode: {
    smzf: "扫码支付",
    xdsb: "二维码生成失败,请重新下单",
    fhyxck: "您可返回游戏查看",
  },
};
